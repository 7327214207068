@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';

.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: bold;
}
.h6 {
  font-weight: normal;
}

.ctaContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 16px;

  .cta {
    display: block;
    border: 1px solid;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    width: 150px;
    text-align: center !important;
  }
}

.mainText {
  word-wrap: break-word;
  a {
    color: $tp-house-pink;
  }
}
